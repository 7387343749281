import React from "react";
import { Layout, PortfolioNav, PortfolioHeader } from "../../../components";
import locales from "../../../constants";

import ReactPlayer from "react-player";
import MapOfAttractions from "../../../components/mapOfAttractions";

const AlicjaWKrainieCzarow = ({ pageContext: { slug } }) => {
  const nav = {
    initialSlideIndex: 1,
  };
  const lang = "en";
  return (
    <Layout
      seo={{
        title: "Alice in Wonderland",
        href: slug,
        lang: "en",
      }}
      header={{
        background:
          "linear-gradient( 45deg, #8c0079, #990885, #a80792, #881f79, #d64ac2 )",
        icons: "#8c0079",
        navClass: "alicja-w-krainie-czarow",
        ogImage: require("../../../assets/img/portfolio/alicja-w-krainie-czarow_top_back.jpg"),
      }}
      langSwitch={{
        langKey: "en",
        langSlug: "/en/projects/alice-in-wonderland/",
      }}
    >
      <PortfolioHeader name="alicja-w-krainie-czarow" height="800" />
      <section
        className="container-fluid alicja-w-krainie-czarow-section-1"
        id="info"
      >
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1>Alice in Wonderland</h1>
              <h1>Garden of Lights</h1>
              <ul>
                <li>Website</li>
                <li>Logo</li>
                <li>Key Visual</li>
                <li>Map of attractions</li>
                <li>Printed materials</li>
                <li>Copywriting</li>
                <li>Promotional video of the event</li>
                <li>Marketing Campaign</li>
                <li>
                  Website and marketing campaign administration during the event
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="inner">
              <p>
                The multimedia world of Alice in Wonderland was created in
                Stanislaw Lem Science Garden in Krakow, in which millions of
                colorful lights and spectacular installations of the heroes of
                Charles Lutwidge Dodgson's novels were used. For over 4 months
                the inhabitants of Krakow and all of Poland could enjoy a
                fairy-tale autumn walk, and then a winter walk, which delighted
                both large and small guests of the wonderful Garden of Lights.
              </p>
              <p>
                We were responsible for the preparation of creative projects for
                Wonderful Lighting from Gdynia, who is the organizator of the
                event. We created the logos, key visuals, map of attractions,
                printed materials, websites and ran marketing campaigns in order
                to promote the event and achieve the set goals during the time
                the even took place.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid alicja-w-krainie-czarow-section-2">
        <div className="row">
          <div className="col-md-6 pa-helper">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/alicja-w-krainie-czarow_main_1.png")}
              alt=""
            />
          </div>
          <div className="col-md-6">
            <div className="inner">
              <h3>Logo</h3>
              <p>
                For the purposes of the exhibition, we have designed a logo that
                combines elements of the story of Alice in Wonderland with a
                magic font, and all of this had to be achieved, while
                maintaining the appropriate standards required for the
                subsequent printing of advertising materials and required
                interactive formats.
              </p>
            </div>
          </div>
        </div>
        <div className="row no-gutters row-1">
          <img
            className="img-fluid"
            src={require("../../../assets/img/portfolio/alicja-w-krainie-czarow_main_2.png")}
            alt=""
          />
        </div>
      </section>

      <section className="container-fluid alicja-w-krainie-czarow-section-3">
        <div className="row">
          <div className="col-xl-7 col-lg-12">
            <div className="inner">
              <h3>Key Visual</h3>
              <p>
                We designed the key visuals of the exhibitions. The biggest
                challenge here was the lack of materials to use before the
                exhibition was opened. The presented project was designed to
                attract the attention of the target groups specified in the
                strategy, as well as to create consistent visual identification
                of the entire event.
              </p>
            </div>
          </div>
        </div>
        <div className="row row-1">
          <img
            className="img-fluid"
            src={require("../../../assets/img/portfolio/alicja-w-krainie-czarow_main_3.png")}
            alt=""
          />
        </div>
      </section>

      <section className="container-fluid alicja-w-krainie-czarow-section-4">
        <div className="row">
          <div className="col-xl-7 col-lg-12">
            <div className="inner">
              <h3>Website</h3>
              <p>
                The website was designed to reflect the magical nature of the
                place, allowing users to find the most important information
                about the exhibition in a quick and comfortable way, as well as
                to enable the sale of tickets and communication of up-to-date
                information about the exhibition.
              </p>
              <p>
                The key aspect of the project was the development of the website
                in a way that allowed for its fast and stable operation even
                during the biggest traffic, as we had to make sure the site is
                going to be working well even with a huge amount of users
                accessing it at the very same time.
              </p>
            </div>
          </div>
        </div>
        <div className="row row-1 no-gutters">
          <div className="col-md-12">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/alicja-w-krainie-czarow_main_4.png")}
              alt=""
            />
          </div>
        </div>
        <div className="row row-2 no-gutters">
          <div className="col-md-6">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/alicja-w-krainie-czarow_main_5.png")}
              alt=""
            />
          </div>
          <div className="col-md-6">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/alicja-w-krainie-czarow_main_6.png")}
              alt=""
            />
          </div>
        </div>
      </section>

      <section className="container-fluid alicja-w-krainie-czarow-section-5">
        <div class="inner">
          <h3>Map of attractions</h3>
          <p>
            We have prepared a map of attractions for visitors, both in an
            interactive and analogue form, thanks to which each visitor could
            easily navigate around the Garden of Lights.
          </p>
        </div>
        {/* <div className="row map-row">
              <div className="col-md-12">
                <img
                    className="img-fluid"
                    src={require("../../../assets/img/portfolio/alicja-w-krainie-czarow_main_7.png")}
                    alt=""
                />
              </div>
          </div> */}
        <div className="view--desktop">
          <section className="map-section">
            <div className="container">
              <div className="row no-gutters align-items-center">
                <div className="col-lg-6 column column--map">
                  <MapOfAttractions />
                </div>
                <div className="col-lg-6 column--text">
                  <div className="map-points">
                    <div className="single-point" data-id="1">
                      <img
                        src={require("../../../assets/img/portfolio/map/map-figure-1.png")}
                        alt="Brama i królik"
                        className="img-fluid"
                      />
                      <div className="single-point__text-container text-container">
                        <p className="text-container__number">1</p>
                        <div className="text-container__text text">
                          <p className="text__name">Brama i Królik</p>
                          <p className="text__desc">Figura świetlna</p>
                        </div>
                      </div>
                    </div>
                    <div className="single-point" data-id="2">
                      <img
                        src={require("../../../assets/img/portfolio/map/map-figure-2.png")}
                        alt="Brama do krainy czarów"
                        className="img-fluid"
                      />
                      <div className="single-point__text-container text-container">
                        <p className="text-container__number">2</p>
                        <div className="text-container__text text">
                          <p className="text__name">
                            Brama do <br />
                            krainy czarów
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="single-point" data-id="3">
                      <img
                        src={require("../../../assets/img/portfolio/map/map-figure-3.png")}
                        alt="Tunel do króliczej nory"
                        className="img-fluid"
                      />
                      <div className="single-point__text-container text-container">
                        <p className="text-container__number">3</p>
                        <div className="text-container__text text">
                          <p className="text__name">
                            Tunel do <br /> króliczej nory
                          </p>
                          <p className="text__desc">Instalacja multimedialna</p>
                        </div>
                      </div>
                    </div>
                    <div className="single-point" data-id="4">
                      <img
                        src={require("../../../assets/img/portfolio/map/map-figure-4.png")}
                        alt="Choinka"
                        className="img-fluid"
                      />
                      <div className="single-point__text-container text-container">
                        <p className="text-container__number">4</p>
                        <div className="text-container__text text">
                          <p className="text__name">Choinka</p>
                          <p className="text__desc">Instalacja multimedialna</p>
                        </div>
                      </div>
                      <p className="single-point__info">
                        <strong>Codziennie</strong> <br />
                        Pokazy „Candela” 16-metrowej choinki multimedialnej z
                        ponad 30 tysiącami multimedialnych punktów LED. Pokazy
                        odbywają się każdego dnia co 30 minut od momentu
                        otwarcia wystawy. <br />
                        <strong>Czas trwania pokazu: 10 minut.</strong>
                      </p>
                    </div>
                    <div className="single-point" data-id="5">
                      <img
                        src={require("../../../assets/img/portfolio/map/map-figure-5.png")}
                        alt="Królicza nora"
                        className="img-fluid"
                      />
                      <div className="single-point__text-container text-container">
                        <p className="text-container__number">5</p>
                        <div className="text-container__text text">
                          <p className="text__name">Królicza nora</p>
                          <p className="text__desc">Figura świetlna</p>
                        </div>
                      </div>
                    </div>
                    <div className="single-point" data-id="6">
                      <img
                        src={require("../../../assets/img/portfolio/map/map-figure-6.png")}
                        alt="Kot z Cheshire"
                        className="img-fluid"
                      />
                      <div className="single-point__text-container text-container">
                        <p className="text-container__number">6</p>
                        <div className="text-container__text text">
                          <p className="text__name">Kot z Cheshire</p>
                        </div>
                      </div>
                    </div>
                    <div className="single-point" data-id="7">
                      <img
                        src={require("../../../assets/img/portfolio/map/map-figure-7.png")}
                        alt="Magiczne Kamienie"
                        className="img-fluid"
                      />
                      <div className="single-point__text-container text-container">
                        <p className="text-container__number">7</p>
                        <div className="text-container__text text">
                          <p className="text__name">
                            Magiczne <br /> kamienie
                          </p>
                          <p className="text__desc">Instalacja multimedialna</p>
                        </div>
                      </div>
                    </div>
                    <div className="single-point" data-id="8">
                      <img
                        src={require("../../../assets/img/portfolio/map/map-figure-8.png")}
                        alt="Znikające obrazy"
                        className="img-fluid"
                      />
                      <div className="single-point__text-container text-container">
                        <p className="text-container__number">8</p>
                        <div className="text-container__text text">
                          <p className="text__name">
                            Znikające <br /> obrazy
                          </p>
                          <p className="text__desc">Instalacja multimedialna</p>
                        </div>
                      </div>
                    </div>
                    <div className="single-point" data-id="9">
                      <img
                        src={require("../../../assets/img/portfolio/map/map-figure-9.png")}
                        alt="Rada gąsienicy"
                        className="img-fluid"
                      />
                      <div className="single-point__text-container text-container">
                        <p className="text-container__number">9</p>
                        <div className="text-container__text text">
                          <p className="text__name">Rada Gąsienicy</p>
                          <p className="text__desc">Figura świetlna</p>
                        </div>
                      </div>
                    </div>
                    <div className="single-point" data-id="10">
                      <img
                        src={require("../../../assets/img/portfolio/map/map-figure-10.png")}
                        alt="Zimowy ogród"
                        className="img-fluid"
                      />
                      <div className="single-point__text-container text-container">
                        <p className="text-container__number">10</p>
                        <div className="text-container__text text">
                          <p className="text__name">Zimowy ogród</p>
                        </div>
                      </div>
                    </div>
                    <div className="single-point" data-id="11">
                      <img
                        src={require("../../../assets/img/portfolio/map/map-figure-11.png")}
                        alt="Zmyślona łąka"
                        className="img-fluid"
                      />
                      <div className="single-point__text-container text-container">
                        <p className="text-container__number">11</p>
                        <div className="text-container__text text">
                          <p className="text__name">Zmyślona łąka</p>
                          <p className="text__desc">Instalacja multimedialna</p>
                        </div>
                      </div>
                      <p className="single-point__info">
                        <strong>
                          Pokazy odbywają się każdego dnia co 15 minut.
                        </strong>
                      </p>
                    </div>
                    <div className="single-point" data-id="12">
                      <img
                        src={require("../../../assets/img/portfolio/map/map-figure-12.png")}
                        alt="Labirynt światła"
                        className="img-fluid"
                      />
                      <div className="single-point__text-container text-container">
                        <p className="text-container__number">12</p>
                        <div className="text-container__text text">
                          <p className="text__name">
                            Labirynt <br /> światła
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="single-point" data-id="13">
                      <img
                        src={require("../../../assets/img/portfolio/map/map-figure-13.png")}
                        alt="Zaczarowane przejście"
                        className="img-fluid"
                      />
                      <div className="single-point__text-container text-container">
                        <p className="text-container__number">13</p>
                        <div className="text-container__text text">
                          <p className="text__name">
                            Zaczarowane <br /> przejście
                          </p>
                          <p className="text__desc">Instalacja multimedialna</p>
                        </div>
                      </div>
                    </div>
                    <div className="single-point" data-id="14">
                      <img
                        src={require("../../../assets/img/portfolio/map/map-figure-14.png")}
                        alt="Zwariowany Podwieczorek"
                        className="img-fluid"
                      />
                      <div className="single-point__text-container text-container">
                        <p className="text-container__number">14</p>
                        <div className="text-container__text text">
                          <p className="text__name">
                            Zwariowany <br /> Podwieczorek
                          </p>
                          <p className="text__desc">Figura świetlna</p>
                        </div>
                      </div>
                    </div>
                    <div className="single-point" data-id="15">
                      <img
                        src={require("../../../assets/img/portfolio/map/map-figure-15.png")}
                        alt="Tęczowa aleja"
                        className="img-fluid"
                      />
                      <div className="single-point__text-container text-container">
                        <p className="text-container__number">15</p>
                        <div className="text-container__text text">
                          <p className="text__name">Tęczowa aleja</p>
                          <p className="text__desc">Instalacja multimedialna</p>
                        </div>
                      </div>
                    </div>
                    <div className="single-point" data-id="16">
                      <img
                        src={require("../../../assets/img/portfolio/map/map-figure-16.png")}
                        alt="Ścieżka multimedialna"
                        className="img-fluid"
                      />
                      <div className="single-point__text-container text-container">
                        <p className="text-container__number">16</p>
                        <div className="text-container__text text">
                          <p className="text__name">
                            Ścieżka <br /> multimedialna
                          </p>
                          <p className="text__desc">Instalacja multimedialna</p>
                        </div>
                      </div>
                      <p className="single-point__info">
                        <strong>
                          Pokazy odbywają się każdego dnia co 15 minut.
                        </strong>
                      </p>
                    </div>
                    <div className="single-point" data-id="17">
                      <img
                        src={require("../../../assets/img/portfolio/map/map-figure-17.png")}
                        alt="Zamek Królowej Kier"
                        className="img-fluid"
                      />
                      <div className="single-point__text-container text-container">
                        <p className="text-container__number">17</p>
                        <div className="text-container__text text">
                          <p className="text__name">
                            Zamek <br /> Królowej Kier
                          </p>
                          <p className="text__desc">Figura świetlna</p>
                        </div>
                      </div>
                    </div>
                    <div className="single-point" data-id="18">
                      <img
                        src={require("../../../assets/img/portfolio/map/map-figure-18.png")}
                        alt="Pokazy multimedialne"
                        className="img-fluid"
                      />
                      <div className="single-point__text-container text-container">
                        <p className="text-container__number">18</p>
                        <div className="text-container__text text">
                          <p className="text__name">
                            Pokazy laserowe <br /> i multimedialne
                          </p>
                          <p className="text__desc">Instalacja multimedialna</p>
                        </div>
                      </div>
                      <p className="single-point__info">
                        <strong>Codziennie</strong>
                        <br />
                        Pokazy laserowe przy akompaniamencie „Alice’s Theme” z
                        oficjalnej ścieżki dźwiękowej Alicji w Krainie Czarów
                        Tima Burtona. <br />
                        <br />
                        Pokazy laserowe odbywają się co 30 minut, rozpoczynając
                        od godziny 18:15.
                        <br />
                        <strong>Czas trwania pokazu: 5 minut.</strong>
                      </p>
                    </div>
                    <div className="single-point" data-id="19">
                      <img
                        src={require("../../../assets/img/portfolio/map/map-figure-19.png")}
                        alt="Święty Mikołaj"
                        className="img-fluid"
                      />
                      <div className="single-point__text-container text-container">
                        <p className="text-container__number">19</p>
                        <div className="text-container__text text">
                          <p className="text__name">Święty Mikołaj</p>
                          <p className="text__desc"> </p>
                        </div>
                      </div>
                      <p className="single-point__info">
                        <strong>
                          Atrakcja będzie dostępna codziennie w godzinach od
                          17:00 do 21:00
                        </strong>
                        <br />
                        <br />
                        Święty Mikołaj zagości w naszej Krainie Czarów od 6 do
                        23 grudnia, w pięknym, czerwonym domku - rodem z
                        Laponii. Przy przedświątecznych obowiązkach pomagać mu
                        będzie dzielna Elfka, a cały Ogród Świateł ogarnie magia
                        zbliżających się świąt. 
                        <br />
                        <br />
                        Podczas odwiedzin w domku Świętego Mikołaja będzie
                        możliwość wykonania samodzielnie zdjęć. 
                        <br />
                        <br />
                        <strong>
                          Mając na uwadze środki ostrożności, Święty Mikołaj
                          będzie znajdował się w odpowiednim dystansie od
                          zwiedzających.
                        </strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="view view--mobile">
          <div className="subpage-header subpage-header--map">
            <h1 className="subpage-header__title">Wersja mobilna</h1>
            <h3 className="subpage-header__subtitle">Mapa do pobrania</h3>
          </div>
          <p className="view__text">
            Sprawdź jakie czekają <br /> na ciebie atrakcje. <br />
          </p>
          <a
            href={require("../../../assets/downloads/mapa_atrakcji.pdf")}
            className="btn-alicja btn-alicja--shadow"
            target="_blank"
            rel="noreferrer"
          >
            Pobierz mapę
          </a>
        </div>
      </section>

      <section className="container-fluid alicja-w-krainie-czarow-section-6">
        <div className="row">
          <div className="col-lg-6 order-helper">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/alicja-w-krainie-czarow_main_8.png")}
              alt=""
            />
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/alicja-w-krainie-czarow_main_9.png")}
              alt=""
            />
          </div>
          <div className="col-lg-6 order-helper">
            <div className="inner">
              <h3>Marketing Campaign</h3>
              <p>
                After more than 4 months of intensive marketing campaign, we can
                boast of results that confirm the great success of the entire
                project. From the very beginning, the content we published
                aroused great interest, which generated huge organic reach and
                resulted in a big amount of free views of the content published
                by the profiles of Alice in Wonderland.
              </p>
              <p>Here are the results broken down by Facebook and Instagram:</p>
              <h5>Facebook</h5>
              <ul>
                <li>29,546 Facebook fans in 4 months!</li>
                <li>33,649 activities on posts</li>
                <li>2 720 comments on posts</li>
                <li>1,673 shared posts</li>
                <li>The average daily organic reach: 32,344 people!</li>
                <li>The average daily viral reach: 26,929 people!</li>
                <li>
                  Over 45,000 people attended or expressed interest of the event
                  on Facebook
                </li>
              </ul>
              <h5>Instagram</h5>
              <ul>
                <li>5,445 followers on Instagram in 4 months</li>
                <li>More than 17,000 activities on posts</li>
                <li>The average daily reach: 21,523 people</li>
                <li>
                  Cooperation with over 75 influencers from Krakow and the
                  surrounding areas
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid alicja-w-krainie-czarow-section-7">
        <div className="inner">
          <h3>Promotional video of the event</h3>
          <p>
            We made a video of the event, showing how colorful and beautiful the
            exhibition was, and how much interest and commitment it aroused on
            the part of the visitors.
          </p>
        </div>
        <div className="video">
          <ReactPlayer
            url="https://wp.adream.pl/wp-content/themes/adream/videos/alicja_video.mp4"
            autoplay="true"
            controls="true"
            muted="true"
            playing="false"
            playsinline="true"
          />
        </div>
      </section>

      <section className="container-fluid alicja-w-krainie-czarow-section-8">
        <div className="row">
          <div className="offset-xl-6 col-xl-6 offset-lg-5 col-lg-7 offset-md-5 col-md-7">
            <div className="inner">
              <h3>Printed materials</h3>
              <p>
                For the purpose of promoting the event, we have prepared a
                number of printable projects for various advertising formats,
                such as trams, advertising poles, citylights, printed and
                digital tickets.
              </p>
            </div>
          </div>
        </div>
        <div className="row row-1">
          <img
            className="img-fluid"
            src={require("../../../assets/img/portfolio/alicja-w-krainie-czarow_main_11.png")}
            alt=""
          />
        </div>
      </section>

      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};
export default AlicjaWKrainieCzarow;
